import React from 'react';
import { Helmet } from 'react-helmet';
import FeaturesSection from '../components/AIHubPage/FeaturesSection';
import HowItWorks1 from '../components/MessageHubPage/HowItWorks1';
import HowItWorks2 from '../components/MessageHubPage/HowItWorks2';
import Testmonials from '../components/Testimonials';
import TrustedCustomers from '../components/TrustedCustomers';
import pagesJsonLD from '../constants/pagesJsonLD';
import './RelevaAIHub.css';

const MessageHub = () => {
  // Function to handle clicks on the card wrappers
  const handleCardWrapperClick = (e, slider) => {
    e.preventDefault(); // Prevent default behavior of the click event if needed
    showElements(`.slider${slider}`, 'image');
    showElements(`.text${slider}`, 'text');
    toggleActive(`.interactiveHeading${slider}`);
  };
  // Function to toggle "active" class on the clicked element and remove it from others
  const toggleActive = (className) => {
    // Get all elements with the specified class name
    const elements = document.querySelectorAll(className);

    // Remove "active" class from all elements
    document.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });

    // Add "active" class to the clicked element
    elements.forEach((element) => {
      element.classList.add('active');
    });
  };
  // Function to show all elements with the specified class name
  const showElements = (className, type) => {
    // Get all elements with the specified class name
    const elementsToShow = document.querySelectorAll(className);

    // Convert NodeList to an array using spread operator
    const elementsArray = [...elementsToShow];

    // Show all elements
    elementsArray.forEach((element) => {
      element.style.display = 'block';
    });

    // Hide all other images
    if (type === 'image') {
      const allImages = document.querySelectorAll('.interactiveSlider');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
    if (type === 'text') {
      const allImages = document.querySelectorAll('.interactiveText');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
  };

  return (
    <div className="releva-ai-hub omni-personalization">
      <Helmet>
        <title>Releva AI | Message Hub</title>
        <meta
          name="description"
          content="Enhance your marketing with Releva AI's Mail and Push solutions. Automate personalized email and push notifications to boost engagement and conversions. Discover how our AI technology can transform your outreach strategies."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.messageHubPage)}</script>
      </Helmet>
      <section className="else-statement">
        <div className="while-loop">
          <div className="relevas-ai-hub">
            Efficiency in Communication: Automate Messaging Across Users' Favorite Channels
          </div>
          <div className="switch-case-ai">
            <div className="create-document-collaborate">
              Design workflows that enhance your communication strategy by ensuring brand consistency, effectively
              engaging, and precisely targeting your audience. Drive meaningful interactions via SMS, Viber, and
              WhatsApp, while also saving on retargeting costs.
            </div>
          </div>
          <div className="break-point">
            <div className="button1">
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                href="https://relevaad.zohobookings.eu/#/187906000000034054"
              >
                Try Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="call-stack">
        <img className="image-118-icon" loading="lazy" alt="" src="/MessageHubPage/HeroImage.webp" />
      </section>
      <section className="global-scope">
        <div className="block-statement">
          <div className="achievements-recognition">{`Achievements & Recognition `}</div>
          <div className="achievements-recognition-parent">
            <div className="arithmetic-operator">
              <div className="relevas-global-impact">Releva's global impact on eCommerce from around the world.</div>
            </div>
          </div>
          <div className="frame-container">
            <div className="frame-wrapper"></div>
            <div className="awardsHolder">
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements1.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements2.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements3.webp" />
            </div>
          </div>
          <div className="array-operator">
            <div className="object-operator">
              <div className="function-define">
                <div className="why-your-ecommerce">Why Your eCommerce Business Needs Releva Message Hub?</div>
              </div>
              {/* make it interactive */}
              <div className="variable-declare">
                <div className="variable-update">
                  <div className="statement-list">
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading1 active"
                      onClick={(e) => handleCardWrapperClick(e, 1)}
                    >
                      <b className="struggling-to-persuade">Is inconsistent messaging reducing customer engagement?</b>
                      <div className="convey-in-the interactiveText text1">
                        Deliver cohesive and compelling communication across SMS, WhatsApp, and Viber with Releva's
                        Message Hub. Streamline your messaging strategy, increase engagement rates, and build lasting
                        relationships.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading2"
                      onClick={(e) => handleCardWrapperClick(e, 2)}
                    >
                      <b className="struggling-to-persuade">
                        Finding it hard to manage complex communication workflows?
                      </b>
                      <div className="convey-in-the interactiveText text2">
                        Simplify the management of multichannel communication workflows. Design and automate processes
                        to ensure consistent and timely messaging, freeing up time to focus on business strategies and
                        customer satisfaction.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading3"
                      onClick={(e) => handleCardWrapperClick(e, 3)}
                    >
                      <b className="struggling-to-persuade">Struggling to maintain customer interest post-purchase?</b>
                      <div className="convey-in-the interactiveText text3">
                        Keep your customers engaged after the sale with automated, personalized follow-ups through their
                        preferred channels. Releva’s Message Hub helps to foster customer loyalty and encourage repeat
                        business by maintaining meaningful communication.
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="image-118-icon interactiveSlider  slider1"
                  loading="lazy"
                  alt=""
                  src="/MessageHubPage/WhySlider1.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider2"
                  loading="lazy"
                  alt=""
                  src="/MessageHubPage/WhySlider2.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider3"
                  loading="lazy"
                  alt=""
                  src="/MessageHubPage/WhySlider3.webp"
                />
              </div>
              {/* make it interactive */}
            </div>
          </div>
        </div>
      </section>
      <Testmonials />

      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://relevaad.zohobookings.eu/#/187906000000034054"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks">
            <div className="title">
              <div className="how-releva-ai">How Releva Message Hub Works</div>
              <div className="releva-ai-hub1">Releva Message Hub consists of </div>
            </div>
            <div className="sub-menu">
              <div className="menu-items">
                <div className="brand-identity-wrapper">
                  <div className="brand-identity1">
                    <a className="dummyLink" href="#campaigns">
                      Campaigns
                    </a>
                  </div>
                </div>
                <div className="create-content-wrapper">
                  <div className="create-content">
                    <a className="dummyLink" href="#workflows">
                      Workflows
                    </a>
                  </div>
                </div>
                <div className="generated-content-wrapper">
                  <div className="generated-content">
                    <a className="dummyLink" href="#sms">
                      SMS
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#whatsApp">
                      WhatsApp
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#viber">
                      Viber
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks1-wrapper">
            <div className="howitworks1" id="campaigns">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">01</div>
                  </div>
                  <div className="extract-your-brand">Smart Suggestions:</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">
                      Boost Conversions & Sales with Tailored Product Recommendations 
                    </div>
                  </div>
                  <div className="simply-provide-your">
                    By analyzing user behaviors, Releva provides suggestions that resonate with each individual user’s
                    preferences, significantly boosting satisfaction and increasing sales through a personalized
                    shopping experience.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MessageHubPage/omni1.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="workflows">
            <HowItWorks1 />
          </div>

          <div className="howitworks1-wrapper" id="sms">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">03</div>
                  </div>
                  <div className="extract-your-brand">Elevate Your Personalized SMS</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">Campaigns for Maximum Reach</div>
                  </div>
                  <div className="simply-provide-your">
                    Boost engagement instantly with Releva’s SMS feature—delivering personalized messages right to your
                    customers' mobile phones. Pinpoint the most effective communication channels for maximum engagement.
                    Perfect for promotions, updates, or exclusive offers, the SMS marketing ensures your messages
                    capture attention and prompt immediate action, enhancing response rates and engagement.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MessageHubPage/omni3.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="whatsApp">
            <HowItWorks2 />
          </div>

          <div className="howitworks1-wrapper" id="viber">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">05</div>
                  </div>
                  <div className="extract-your-brand">Leverage Viber for Personalized</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">Customer Journeys</div>
                  </div>
                  <div className="simply-provide-your">
                    But what if your users prefer Viber communicationz? By integrating Viber into your marketing mix,
                    you can deliver personalized messages and customer service communications to a wider audience. This
                    approach enhances customer touchpoints and drives engagement, helping you connect with customers
                    across various demographics and regions.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MessageHubPage/omni5.webp" />
            </div>
          </div>
        </div>
      </section>
      <TrustedCustomers />
      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://relevaad.zohobookings.eu/#/187906000000034054"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FeaturesSection title="Releva goes beyond just messaging marketing." />
      <Testmonials />
      <div className="separator"></div>
    </div>
  );
};
export default MessageHub;
