import './LogicGate1.css';

const LogicGate1 = () => {
  return (
    <section className="logic-gate13">
      <div className="frame-parent99">
        <div className="frame-wrapper38">
          <div className="customer-loyalty-parent">
            <h1 className="customer-loyalty">Customer Loyalty</h1>
            <div className="an-essential-choice">
              An essential choice for marketers prioritizing long-term customer retention.
            </div>
            <div className="button35">
              <a rel="noopener noreferrer" href="https://relevaad.zohobookings.eu/#/187906000000034054" target="_blank">
                <div className="button-name28">Book a demo</div>
              </a>
            </div>
          </div>
        </div>
        <div className="i-13">
          <img
            loading="lazy"
            src="/OverviewPage/CustomerLoyalty.webp"
            alt="CustomerLoyalty"
            title="CustomerLoyalty"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default LogicGate1;
