import './FrameComponent25.css';

const FrameComponent25 = () => {
  return (
    <section className="section-03-wrapper">
      <div className="section-031">
        <div className="error-handler4">
          <div className="ready-to-see-action-button-parent">
            <div className="ready-to-see3">03</div>
            <div className="strategy-customization-and-aut-parent">
              <h1 className="strategy-customization-and">Strategy Customization and Automation</h1>
              <div className="with-these-insights">
                With these insights at your disposal, you can embark on a journey of strategy customization and
                automation. Releva empowers you to create highly personalized communications and offers that resonate
                with individual customers. The best part? Our platform automates the execution of these campaigns across
                multiple digital channels, from email to social media, saving up 8h per weeka and ensuring your message
                reaches your audience when and where it matters most.
              </div>
              <div className="button30">
                <a
                  rel="noopener noreferrer"
                  className="button-name27"
                  target="_blank"
                  href="https://relevaad.zohobookings.eu/#/187906000000034054"
                >
                  Book a demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="analytics-function">
          <img
            loading="lazy"
            src="/OverviewPage/StrategyCustomizationAutomation.webp"
            alt="StrategyCustomizationAutomation"
            title="StrategyCustomizationAutomation"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent25;
