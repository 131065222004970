import './FrameComponent17.css';

const FrameComponent17 = () => {
  return (
    <section className="container-shapes-wrapper">
      <div className="container-shapes">
        <div className="paid-search-wrapper">
          <div className="paid-search">
            <h1 className="web-personalization">Web Personalization</h1>
            <div className="your-visitor-lands">
              Your visitor lands on your online store, browses a favorite item, but it's not in their desired color.
              Releva steps in, transforming their shopping experience.
            </div>
            <button className="button15">
              <a
                rel="noopener noreferrer"
                className="button-name13"
                target="_blank"
                href="https://relevaad.zohobookings.eu/#/187906000000034054"
              >
                Book a demo
              </a>
            </button>
          </div>
        </div>
        <div className="i-01">
          <img
            loading="lazy"
            src="/ProductPage/WebPersonalization.webp"
            alt="WebPersonalization"
            title="WebPersonalization"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent17;
